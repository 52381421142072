export const getPagHefestoLayerConstants = `query getPagHefestoLayerConstants($input: PageInputFilters) {
    getPagHefestoLayerConstants(input: $input) {
      data {
        id
        value {
          id
          label
          slack_channel
          confluence_space
          confluence_parent
          stack {
            id
            label
          }
          dev {
            id
            label
            title
            env {
              id
              type
            }
            stack {
              id
              label
            }
          }
          stg {
            id
            label
            title
            env {
              id
              type
            }
            stack {
              id
              label
            }
          }
          pro {
            id
            label
            title
            env {
              id
              type
            }
            stack {
              id
              label
            }
          }
          jenkins {
            id
            domain
            private_url
            account {
              id
              label
              title
              env {
                id
                type
              }
              stack {
                id
                label
              }
            }
            webhook {
              username
              token
              url
            }
          }
          github {
            alias
            domain
            url
            ssh
            organization
            credentials_id
            jenkins_collaborator_id
          }
          roles {
            singular
            plural
          }
        }
        audit {
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
      pageIndex
      pageSize
      totalPages
      totalItems
    }
  }`;
